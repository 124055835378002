import React, { useState } from 'react'
import index from './index.css'
import { RxCross2 } from "react-icons/rx";

export const Carter = ({carter}) => {
    
  return (
    <>
    <div className={carter==true ? 'carter-main' : "carter-main hide"}>
    <div className={carter==true ? 'carter' : "carter movex"}>
       <div className='dummy'>Shopping Bag is Empty</div> 
        
    </div>
    </div>
    
    </>
  )
}
