import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import index from './index.css'

export const Comingsoon = () => {
    const navigate = useNavigate();
    

    return (
        <div className='coming-soon'>
            <div className='c-s'>Coming Soon...</div>
            <button onClick={() => { navigate('/contact') }}>Join Waitlist</button>
        </div>
    );
}
