import React from 'react'
import { Navbar } from '../Navbar'
import { Footer } from '../Footer'
import { Connect } from './Connect'

export const Contactus = () => {
  return (
    <>
    <Navbar/>
    <Connect/>
    <Footer/>
    </>
  )
}
