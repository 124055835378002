import React, { useEffect } from 'react';
import index from './index.css';
import of1 from '../../../assets/of1.avif';
import of2 from '../../../assets/of2.avif';
import of3 from '../../../assets/of3.avif';
import of4 from '../../../assets/of4.avif';

export const Offer = () => {
    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              entry.target.classList.add('show'); // Add 'show' class when in viewport
            }
          });
        }, { threshold: 0.1 }); // Adjust the threshold as needed
    
        const elements = document.querySelectorAll('.feature');
        elements.forEach(element => observer.observe(element));
    
        return () => {
          elements.forEach(element => observer.unobserve(element)); // Cleanup on unmount
        };
      });
   
    // useEffect(() => {
    //     const features = document.querySelectorAll('.feature');

    //     const isInViewport = (element) => {
    //         const rect = element.getBoundingClientRect();
    //         return (
    //             rect.bottom <= (window.innerHeight || document.documentElement.clientHeight - 250)
    //         );
    //     };

    //     const debounce = (func, delay) => {
    //         let timer;
    //         return function() {
    //             const context = this;
    //             const args = arguments;
    //             clearTimeout(timer);
    //             timer = setTimeout(() => {
    //                 func.apply(context, args);
    //             }, delay);
    //         };
    //     };

    //     const handleScroll = debounce(() => {
    //         features.forEach((feature) => {
    //             if (isInViewport(feature)) {
    //                 feature.classList.add('show');
    //             } else {
    //                 feature.classList.remove('show');
    //             }
    //         });
    //     }, 25); // Adjust the delay as needed

    //     window.addEventListener('scroll', handleScroll);

    //     // Cleanup the event listener
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);


    return (
        <>
            <div className='offer-main'>
                <div className='offer'>
                    <div className='about'>
                        <div className='flex-col'>
                            <div className='c-1'>About Us</div>
                            <div className='c-2'>What Do we Offer ?</div>
                        </div>
                        <div className='quality'>We have made quality of our habit. It’s not something that we just strive for – we live by this principle every day. </div>
                    </div>
                    <div className='features'>
                        <div className='feature feature-one'>
                            <img src={of1} alt='Feature 1' />
                            <div className='desc-btn'>
                                <div className='feature-desc'>
                                    <div className='off-num'>01</div>
                                    <div className='big-font'>One Click Skin Analysis</div>
                                </div>
                                <button className='offer-btn'>Scan Now</button>
                            </div>
                        </div>
                        <div className='feature-end feature  '>
                            <img src={of2} alt='Feature 2' />
                            <div className='desc-btn'>
                                <div className='feature-desc'>
                                    <div className='off-num'>02</div>
                                    <div className='big-font'>Skin Score and Skin Id</div>
                                </div>
                                <button className='offer-btn'>Check Now</button>
                            </div>
                        </div>
                        <div className='feature feature-one'>
                            <img src={of3} alt='Feature 3' />
                            <div className='desc-btn'>
                                <div className='feature-desc'>
                                    <div className='off-num'>03</div>
                                    <div className='big-font'>Personalized Products and Routine Recommendations</div>
                                </div>
                                <button className='offer-btn'>Try Now</button>
                            </div>
                        </div>
                        <div className='feature-end feature'>
                            <img src={of4} alt='Feature 4' />
                            <div className='desc-btn'>
                                <div className='feature-desc'>
                                    <div className='off-num'>04</div>
                                    <div className='big-font'>Dermat Consultation (Online/Offline)</div>
                                </div>
                                <button className='offer-btn'>Book Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
