import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Hero } from '../Hero';
import { Store } from '../Store';
import { Community } from '../Community';
import { Contactus } from '../Contactus';



export const Routing = () => {
  return (
    <>
    <Router>
        <Routes>
            <Route path='/' element={<Hero/>}/>
            <Route path='/store' element={<Store/>}/>
            <Route path='/community' element={<Community/>}/>
            <Route path='/contact' element={<Contactus/>}/>
        </Routes>
    </Router>
    </>
  )
}
