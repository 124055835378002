import React, { useState } from 'react';
import index from './index.css';
import heroimg from '../../assets/landing.avif';
import logo from '../../assets/skintalklogo.avif';
import { TbShoppingBag } from "react-icons/tb";
import rocket from '../../assets/rocket.gif'

import { RxCross2 } from "react-icons/rx";
import { Carter } from '../Carter';

import { useLocation, useNavigate } from 'react-router-dom';

export const Navbar = () => {
    const location = useLocation();
    const navigate = useNavigate();

    // Mapping pathnames to corresponding selected values
    const pathToSelected = {
        '/': 1, // Home
        '/community': 2, // Community
        '/store': 3, // Store
        '/contact': 4 // Contact Us
    };

    // Getting the selected value based on the current pathname
    const selectedone = pathToSelected[location.pathname];
    const [selected, setSelected] = useState(selectedone);
    const [hovered, setHovered] = useState(null);
    const [hoveredcart, setHoveredcart] = useState(false);
    const [crossenable, setCrossEnable] = useState(false)
    const [carter,setCarter] = useState(false)
    const drop=()=>{
        setCrossEnable(!crossenable)
    }
    

    
    return (
        <>
            <div className='navbar'>
                <div className='inner-nav'>
                   <div className='child-1'>
                    <div className='hamburg-main'>
                   <div className='hamburg' onClick={drop}>
                        <div className={crossenable === true ? 'line-1-cust line-1' : 'line-1' }></div>
                        <div className={crossenable === true ? 'line-2-cust line-2' : 'line-2' }></div>
                        <div className={crossenable === true ? 'line-3-cust line-3' : 'line-3' }></div>
                        

                        </div>
                        </div>
                        <img src={logo} className='logo-hero' onClick={() => {setSelected(1);navigate('/')}} />
                   </div>
                    
                    <div className='options'>
                        <div className={selected === 1 || hovered === 1 ? 'option selected-option' : 'option' } 
                            onClick={() => {setSelected(1);navigate('/')}} 
                            onMouseEnter={() => setHovered(1)} 
                            onMouseLeave={() => setHovered(null)}>Home</div>
                        <div className={selected === 2 || hovered === 2 ? 'option selected-option' : 'option'} 
                            onClick={() => {setSelected(2);navigate('/community')}} 
                            onMouseEnter={() => setHovered(2)} 
                            onMouseLeave={() => setHovered(null)}>Community</div>
                        <div className={selected === 3 || hovered === 3 ? 'option selected-option' : 'option'} 
                           onClick={() => {setSelected(3);navigate('/store')}} 
                            onMouseEnter={() => setHovered(3)} 
                            onMouseLeave={() => setHovered(null)}>Store</div>
                        <div className={selected === 4 || hovered === 4 ? 'option selected-option' : 'option'} 
                            onClick={() => {setSelected(4);navigate('/contact')}} 
                            onMouseEnter={() => setHovered(4)} 
                            onMouseLeave={() => setHovered(null)}>Contact Us</div>
                    </div>
                    <div className={hoveredcart==true ? 'cart-opener selected-option' : 'cart-opener'} onClick={()=>setCarter(true)}
                    onMouseEnter={() => setHoveredcart(true)} 
                    onMouseLeave={() => setHoveredcart(false)}>
                        <TbShoppingBag style={{fontSize:'24px'}}/>
                        (0)
                    </div>
                </div>
            </div>
            
            <div className={crossenable==true ? 'dropdown dropdown-display':'dropdown'}>
            <div className={selected === 1 || hovered === 1 ? 'option selected-option' : 'option' } 
                           onClick={() => {setSelected(1);navigate('/')}} 
                            onMouseEnter={() => setHovered(1)} 
                            onMouseLeave={() => setHovered(null)}>Home</div>
                        <div className={selected === 2 || hovered === 2 ? 'option selected-option' : 'option'} 
                            onClick={() => {setSelected(2);navigate('/community')}} 
                            onMouseEnter={() => setHovered(2)} 
                            onMouseLeave={() => setHovered(null)}>Community</div>
                        <div className={selected === 3 || hovered === 3 ? 'option selected-option' : 'option'} 
                            onClick={() => {setSelected(3);navigate('/store')}} 
                            onMouseEnter={() => setHovered(3)} 
                            onMouseLeave={() => setHovered(null)}>Store</div>
                        <div className={selected === 4 || hovered === 4 ? 'option selected-option' : 'option'} 
                            onClick={() => {setSelected(4);navigate('/contact')}} 
                            onMouseEnter={() => setHovered(4)} 
                            onMouseLeave={() => setHovered(null)}>Contact Us</div>
                
            </div>
            <Carter onClick={()=>{setCarter(true)}} carter={carter}/>
            {carter==true ?<RxCross2 className='cross' onClick={()=>{setCarter(false)}}/> : null}
        </>
    );
}
