import React, { useState } from "react";
import index from "./index.css";
import loader from '../../../assets/loader.gif'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';


export const Connect = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: ""
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.phone.length !== 10) {
      toast.error("Phone number must be 10 digits long");
      return; // Don't submit the form if phone number is not valid
  }

    try {
      const response = await fetch("https://skin-backnew.onrender.com", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(formData)
      });
    
      const responseData = await response.json(); // Parse JSON response
    
      if (response.ok) {
        // Handle successful submission
        console.log("Form submitted successfully!");
        toast.success(responseData.message); // Access message from response data
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        // Handle errors
        toast.error(responseData.message); 
        // console.error("Failed to submit form:", responseData);
        
      }
    } catch (error) {
      // console.error("Error submitting form:", error);
      toast.error("An error occurred while submitting the form.");
    }
    
  };

  return (
    <div className="connect">
      <div className="contact-head">
        <div className="heading">Let's Connect</div>
        <div className="brief-contact">
          Feel free to connect with any questions or concerns. You can use the
          form on our website or email us directly. We appreciate your interest
          and look forward to hearing from you.
        </div>
      </div>
      <div className="form-location">
        <form className="form" onSubmit={handleSubmit}>
          <div className="name">
            <label>Name *</label>
            <input
              placeholder="Your Name"
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="phone">
            <label>Phone *</label>
            <input
              placeholder="Your Phone"
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
          <div className="email">
            <label>Email *</label>
            <input
              placeholder="Your Email Address"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="message">
            <label>Message *</label>
            <textarea
              type="text"
              placeholder="Your Message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit">Submit</button>
        </form>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d13991.915240949473!2d77.10533915592028!3d28.750049609608897!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sdelhi%20technological%20university!5e0!3m2!1sen!2sin!4v1712495831850!5m2!1sen!2sin"
          width="600"
          height="450"
          alt={loader}
          style={{ border: 0 }}
          className="map"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Google Map"
        />
      </div>
    </div>
  );
};
