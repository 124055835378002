import React from "react";
import index from "./index.css";
import landing from "../../../assets/landing.avif";
import { useNavigate } from "react-router-dom";
import imgtop from '../../../assets/imgtop.jpg'
import landbot from '../../../assets/landbot.jpg'

export const Intro = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="intro-hero">
        <div className="inner-intro-hero">
          <div className="hero-text">
          <img src={landbot}/>
            <div className="leading">Leading Virtual A.I. Skincare</div>
            <div className="introd">
              Introducing SkinTalk,the Future of Personalized{" "}
              <div>Skincare</div>
            </div>
            <div className="principle">
              We have made quality our habit. It’s not something that we just
              strive for – we live by this principle every day.{" "}
            </div>
            <div className="scan-ratings">
            
              <button
                className="scan"
                onClick={() => {
                  navigate("/contact");
                }}
              >
               
                Let's Scan
              </button>
            </div>
          </div>
          <div className="img-grad">
            {/* <div className="gradient-background"></div> */}
            <img src={imgtop} className="img-top"/>
            <img className="land-img" src={landing} />
          </div>
        </div>
      </div>
    </>
  );
};
