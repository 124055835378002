import React from 'react'
import { Navbar } from '../Navbar'
import { Comingsoon } from '../Comingsoon'
import { Footer } from '../Footer'

export const Community= () => {
  return (
    <>
    <Navbar/>
    <Comingsoon/>
    <Footer/>
    </>
  )
}
