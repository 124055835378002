import React from 'react'
import index from './index.css'
import logo from '../../assets/skintalklogo.avif'
import { FaXTwitter } from "react-icons/fa6";
import { SlSocialLinkedin } from "react-icons/sl";
import { FaFacebook } from "react-icons/fa6";

import { SlSocialInstagram } from "react-icons/sl";

export const Footer = () => {
  return (
    <>
    <div className='footer'>
        <div className='inner-footer'>
            <div className='logo-social'>
                
                    <img src={logo}/>
                
                <div className='social'>
                    <FaFacebook/>
                    <FaXTwitter/>
                    <SlSocialInstagram/>
                    <SlSocialLinkedin/>
                </div>
            </div>
            <div className='rest'>
                <div style={{display:'flex',flexDirection:'column',gap:'12px',fontWeight:'550'}}>
                    <div>Home</div>
                    <div>Community</div>
                    <div>Contact Us</div>
                </div>
                <div style={{display:'flex',flexDirection:'column',gap:'12px'}}>
                    <div style={{fontSize:'18px',fontWeight:'600'}}>
                        Contact Us
                    </div>
                    <div>+91 9718325800</div>
                </div>
                <div style={{display:'flex',flexDirection:'column',gap:'12px'}}>
                    <div style={{fontSize:'18px',fontWeight:'600'}}>
                        Address
                    </div>
                    <div>New Delhi</div>
                    <div>India</div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
