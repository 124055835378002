import React from "react";
import index from "./index.css";
import { Navbar } from "../Navbar";
import { Intro } from "./Intro";
import { Offer } from "./Offer";
import { Footer } from "../Footer";
import { useNavigate } from 'react-router-dom';


export const Hero = () => {
  const navigate = useNavigate();
  return (
    <>
      <Navbar />
      <div className="Join-now">
        <div>
          🚀 <a onClick={()=>{navigate('/contact')}} style={{cursor:'pointer'}}>Click Here to Join</a> the Exclusive Waitlist
           {/* and
          Be Among the First Users! */} 
            🚀
        </div>
      </div>
      <Intro />
      <Offer />
      <Footer />
    </>
  );
};
